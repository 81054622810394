<template>
  <div class="w-100">
    <!--begin::Content header-->
    <div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">

    </div>
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="row">
      <div class="col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 col-xxl-4 offset-xxl-4 login-form login-signin">
        <div class="text-center mb-10 mb-lg-20">
          <h3 class="font-size-h1">Forgot password</h3>
          <p class="text-muted font-weight-semi-bold">
            Enter your email address
          </p>
        </div>

        <!-- begin::Alert -->
        <div v-if="alert" class="alert alert-info mt-n10 mb-10 text-center">
          An email is sent with instructions to reset your password.
        </div>
        <!-- end::Alert -->

        <!--begin::Form-->
        <form class="form" @submit.stop.prevent="onSubmit">
          <div class="form-group">
            <input
              ref="email"
              type="email"
              placeholder="Email address"
              :class="['form-control form-control-solid h-auto py-5 px-6', {'is-invalid': $v.form.email.$error}]"
              :disabled="isLoadingForm"
              v-model="$v.form.email.$model"
            />
          </div>

          <!--begin::Action-->
          <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
            <router-link :to="{ path: '/login', query: { email: (form.email || undefined) }}" class="text-dark-60 text-hover-primary my-3 mr-2">
              Back to login
            </router-link>
            <button
              ref="kt_login_signin_submit"
              class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
              :disabled="isLoadingForm"
            >
              Submit
            </button>
          </div>
          <!--end::Action-->
        </form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { validations } from '@vedicium/core-vue';

import User from '@/libs/classes/user';

export default {
  mixins: [validationMixin],
  name: 'authForgotPassword',
  data () {
    return {
      alert: false,
      isLoadingForm: false,
      form: {
        email: null,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },

  async mounted () {
    // Add email if set by query
    if (this.$route.query && this.$route.query.email) {
      this.$set(this.form, 'email', this.$route.query.email);
    }

    await this.$nextTick();
    this.$refs.email.focus();
  },

  methods: {
    async validate () {
      const validationArray = [this.$v];

      // Run the validations
      await validations.validateArray(validationArray);
    },
    async resetForm () {
      this.$errors.clear({ component: this });
      this.$set(this, 'form', { email: null });
      await this.$nextTick();
      this.$v.$reset();
    },
    async onSubmit () {
      this.$errors.clear({ component: this });
      this.$set(this, 'isLoadingForm', true);
      this.$set(this, 'alert', false);

      // Set spinner to submit button
      const submitButton = this.$refs.kt_login_signin_submit;
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');
      try {
        await this.validate();

        await this.$ws.post(`${User.uri}/password/forgot`, {
          body: {
            username: this.form.email,
            frontend_url: `${window.location.origin}/reset-password`,
          },
        }, { skipAuth: true });
      } catch (e) {
        this.$errors.handle(e, { component: this, ui_element: false });
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
        submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
        this.$set(this, 'alert', true);
      }
    },
  },
};
</script>
